<template>
  <b-card
    no-body
    class="p-2"
  >
    <h3 class="mb-2">
      {{ $t('AppAduana.edit.editCustom') }}
    </h3>
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <!-- Clave field -->
          <b-col
            cols="12"
            md="4"
          >
            <!-- Clave Input -->
            <b-form-group
              :label="$t('AppAduana.columns.keyCode')"
            >
              <b-form-input
                v-model="custom.clave"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                disabled
              />
            </b-form-group>
          </b-col>
          <!-- user Field -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              :label="$t('AppAduana.columns.name')"
            >
              <b-form-input
                v-model="custom.nombre"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                disabled
              />
            </b-form-group>
          </b-col>
          <!-- Password Field -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              :label="$t('AppAduana.columns.nickname')"
            >
              <b-form-input
                v-model="custom.alias"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              />
            </b-form-group>
          </b-col>
          <!-- Custom Type Select -->
          <b-col
            cols="12"
            md="4"
          >
            <!-- Clave Input -->
            <b-form-group
              label="Tipo aduana"
            >
              <v-select
                v-model="custom.aduanaTipoId"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="false"
                label="texto"
                :options="customTypes"
                :reduce="option => option.id"
              />
            </b-form-group>
          </b-col>
          <!-- Habilitado -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              :label="$t('AppAduana.columns.Enabled')"
            >
              <b-form-checkbox
                v-model="custom.habilitado"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          type="submit"
        >
          {{ $t('Lists.saveChanges') }}
        </b-button>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BCard,
  BForm,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,

  BButton,
  BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import generalDataService from '@/services/generalData.service'
import { ref } from '@vue/composition-api'
import router from '@/router'
import { required } from '@validations'
import vSelect from 'vue-select'
import formValidation from '@core/comp-functions/forms/form-validation'

export default {
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
    BFormCheckbox,
    vSelect,
    ValidationObserver,
  },
  setup() {
    /* SERVICES */
    const {
      fetchCustom,
      updateCustom,
      fetchCustomTypesOptions,
    } = generalDataService()

    // Data
    const custom = ref({})
    fetchCustom(router.currentRoute.params, data => {
      custom.value = data
    })
    const customTypes = ref([])
    fetchCustomTypesOptions(data => {
      customTypes.value = data
    })
    /* Events */
    const onSubmit = () => {
      updateCustom(custom.value)
        .then(() => {
          router.push({ name: 'apps-custom-list' })
        })
    }
    /* Validations */
    const AduanaData = ref(JSON.parse(JSON.stringify(custom.value)))
    const resetAduanaData = () => {
      AduanaData.value = JSON.parse(JSON.stringify(custom))
    }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetAduanaData)
    return {
      // Data
      custom,
      customTypes,
      /* Events */
      onSubmit,
      // Validations
      required,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style>

</style>
